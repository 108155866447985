import { UnbindAddressButton, useWaxCloudWalletContext } from '@redreamerlab/uikit';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import NFTHiveLogo from '../../assets/nfthive-logo.png';
import { Row } from '../../styles/common';
import {
  StyledContent,
  StyledLogo,
  StyledNFTHive,
  StyledWrapper,
  StyledUnbindContainer,
} from './Styles';

const Navbar = () => {
  const { waxUser } = useWaxCloudWalletContext();
  return (
    <StyledWrapper>
      <StyledContent justify="space-between">
        <Row>
          <Link to="/">
            <StyledLogo src={Logo} />
          </Link>
          <StyledNFTHive src={NFTHiveLogo} />
        </Row>
        <StyledUnbindContainer>{waxUser && <UnbindAddressButton />}</StyledUnbindContainer>
      </StyledContent>
    </StyledWrapper>
  );
};

export default Navbar;
