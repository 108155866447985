import Button from '@mui/material/Button';
import styled from 'styled-components';
import { Row, fontStyle, size } from '../../../styles/common';

export const StyledMainTitle = styled.div`
  ${fontStyle('24px', '700')};
  font-style: italic;
  font-family: Poppins;
  text-align: center;
  margin-bottom: 50px;
`;

export const StyledWrapper = styled.form`
  padding: 32px 100px;
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledLabel = styled.div`
  min-width: 90px;
  margin-right: 20px;
  text-align: right;
`;

export const StyledInput = styled.input<{ width?: string; mr?: string }>`
  width: ${({ width }) => width || '100%'};
  margin-right: ${({ mr }) => mr};
  padding: 8px;
  border-radius: 4px;
  border: 2px solid #c5ced6;
  outline: 0;
  box-shadow: none;

  ::placeholder {
    color: #d2dbe3;
  }
`;

export const StyledCustomRow = styled(Row)`
  margin-bottom: 16px;

  .basic-single {
    width: 100%;
  }
`;

export const StyledRedeemButton = styled(Button)`
  &&& {
    ${size('178px', '40px')};
    padding: 8px 0;
    background: #BD34D1;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 700;
    text-transform: none;
    margin-right: 16px;
    margin-top: 50px;

    :hover {
      background: #CA74D6;
    },
  }
`;

export const StyledCancelButton = styled(Button)`
  &&& {
    ${size('178px', '40px')};
    padding: 8px 0;
    background: #c3cfd9;
    color: black;
    border-radius: 4px;
    font-weight: 700;
    text-transform: none;
    margin-top: 50px;
    
    :hover {
      background: #e3e3e3;
    },
  }
`;

export const StyledButtonIcon = styled.div<{ src: string; background: string }>`
  margin-right: 8px;
  mask-image: ${({ src }) => `url(${src})`};
  mask-repeat: no-repeat;
  mask-position: center;
  background: ${({ background }) => background};
  ${size('20px', '20px')};
`;
