import Home from '../pages/Home';
import RedemptionsPage from '../pages/RedemptionsPage';

const routes = [
  {
    id: 'home',
    name: 'Home',
    path: '/',
    component: (props: any) => <Home {...props} />,
  },
  {
    id: 'redemption',
    name: 'Redemption',
    path: '/redemptions',
    component: (props: any) => <RedemptionsPage {...props} />,
  },
];

export default routes;
