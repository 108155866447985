import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Navbar from './components/Navbar';
import Router from './routes';
import theme from './styles/theme';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
