import {
  CollectionCard,
  ConnectButton,
  RefreshButton,
  Loading,
  useWaxCloudWalletContext,
} from '@redreamerlab/uikit';
import { useEffect, useState } from 'react';
import { Row } from '../../styles/common';
import ShippingInformation from './ShippingInformation';
import {
  StyledCardId,
  StyledCardTitle,
  StyledGrid,
  StyledTitle,
  StyledWrapper,
  StyledButtonWrapper,
} from './Styles';
import { Redemptions } from './type';
import axios from 'axios';
import humps from 'humps';
import { useHistory } from 'react-router-dom';
import ResponseModal from './ResponseModal';

const RedemptionsPage = () => {
  const { wax, waxUser } = useWaxCloudWalletContext();

  const [redeemAssetId, setRedeemAssetId] = useState<number>(0);
  const [redemptions, setRedemptions] = useState<Redemptions[]>([]);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseModal, setResponseModal] = useState<{
    isOpen: boolean;
    isSuccess: boolean;
    isFailed: boolean;
    error: string;
  }>({ isOpen: false, isSuccess: false, isFailed: false, error: '' });

  const history = useHistory();

  useEffect(() => {
    if (wax) {
      setIsLoading(true);
      axios
        .get(`https://api.nfthive.io/api/redeemable-assets/${waxUser!.account}`)
        .then((res) => {
          const transform = humps.camelizeKeys(res.data);
          setRedemptions(transform as Redemptions[]);
        })
        .finally(() => setIsLoading(false));
    }
  }, [wax, waxUser, refreshCount]);

  useEffect(() => {
    if (!wax) {
      history.push('/');
    }
  }, [wax, history]);

  const handleSuccess = () => {
    setResponseModal({ ...responseModal, isOpen: true, isSuccess: true });
  };

  const handleFailed = (error: Error) => {
    setResponseModal({ ...responseModal, isOpen: true, isFailed: true, error: error.message });
  };

  const handleCloseResponseModal = () => {
    setRefreshCount((prev) => prev + 1);
    setResponseModal({ ...responseModal, isOpen: false });
  };

  return (
    <StyledWrapper>
      <Loading isLoading={isLoading} />
      <Row justify="space-between">
        <div>
          <StyledTitle>Redemption Center</StyledTitle>
          <div>Select an NFT to redeem</div>
        </div>
        <StyledButtonWrapper>
          <RefreshButton
            width="125px"
            height="40px"
            onRefresh={() => setRefreshCount((prev) => prev + 1)}
          />
        </StyledButtonWrapper>
      </Row>
      <StyledGrid>
        {redemptions.map((item) => (
          <CollectionCard
            key={item.asset.assetId}
            imgUrlTransform={`https://ipfs.hivebp.io/preview?collection=kylincreates&hash=${item.asset.collection.image}&size=320`}
            handleRedeem={() => {}}
          >
            <StyledCardTitle>{item.asset.collection.name}</StyledCardTitle>
            <StyledCardId>{`#${item.asset.assetId}`}</StyledCardId>
            <ConnectButton name="Redeem" onClick={() => setRedeemAssetId(item.asset.assetId)} />
          </CollectionCard>
        ))}
      </StyledGrid>
      <ShippingInformation
        assetId={redeemAssetId}
        isOpen={!!redeemAssetId}
        onSuccess={handleSuccess}
        onFailed={handleFailed}
        onClose={() => setRedeemAssetId(0)}
      />
      <ResponseModal
        isOpen={responseModal.isOpen}
        errorMessage={responseModal.error}
        isSuccess={responseModal.isSuccess}
        isFailed={responseModal.isFailed}
        onClose={handleCloseResponseModal}
      />
    </StyledWrapper>
  );
};

export default RedemptionsPage;
