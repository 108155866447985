import { ConnectButton, useWaxCloudWalletContext } from '@redreamerlab/uikit';
import { useHistory } from 'react-router-dom';
import { StyledBoldTitle, StyledButtonContainer, StyledItalicTitle, StyledWrapper } from './Styles';

const Home = () => {
  const history = useHistory();
  const { waxUser, waxActivate, waxDeactivate } = useWaxCloudWalletContext();

  return (
    <StyledWrapper>
      <StyledItalicTitle>Welcome to</StyledItalicTitle>
      <StyledBoldTitle>Redemption Center</StyledBoldTitle>
      <StyledButtonContainer>
        {waxUser ? (
          <ConnectButton
            name="Information"
            onClick={() => {
              history.push('/redemptions');
            }}
          />
        ) : (
          <ConnectButton
            name="connect WAX Wallet"
            onClick={() => {
              waxActivate()
                .then(() => {
                  history.push('/redemptions');
                })
                .catch(() => waxDeactivate());
            }}
          />
        )}
      </StyledButtonContainer>
    </StyledWrapper>
  );
};

export default Home;
