import { Modal, useWaxCloudWalletContext, Loading } from '@redreamerlab/uikit';
import Select from 'react-select';
import { Row } from '../../../styles/common';
import {
  StyledCancelButton,
  StyledCustomRow,
  StyledInput,
  StyledLabel,
  StyledMainTitle,
  StyledRedeemButton,
  StyledWrapper,
  StyledButtonIcon,
} from './Styles';
import exchangeIcon from '../../../assets/exchange.svg';
import closeIcon from '../../../assets/close.svg';
import elevatorIcon from '../../../assets/elevator.png';
import { useState } from 'react';
import axios from 'axios';

type Props = {
  isOpen: boolean;
  assetId: number;
  onSuccess: () => void;
  onFailed: (error: Error) => void;
  onClose: () => void;
};

interface FormField {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  postCode: string;
  country: string;
}

const countries = [
  {
    label: 'Australia',
    value: 'Australia',
  },
  {
    label: 'Japan',
    value: 'Japan',
  },
  {
    label: 'USA',
    value: 'USA',
  },
];

const intialFormFields = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  postCode: '',
  country: '',
};

const ShippingInformation = ({ isOpen, assetId, onClose, onSuccess, onFailed }: Props) => {
  const { wax, waxUser } = useWaxCloudWalletContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<FormField>(intialFormFields);

  const handleUpdateForm = (field: string, value: string) => {
    setFormFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleRequestShippingInfo = async () => {
    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTION_URL}/shippingInfo`, {
      asset_id: '1099872191777',
      shippingInfo: {
        ...formFields,
        additionalAddress: '',
        status: '',
      },
    });
  };

  const handleRedeem = async (e: any) => {
    const checkAllFields = Object.keys(formFields).every(
      (key) => !!formFields[key as keyof FormField],
    );

    if (!checkAllFields) return;
    if (!wax?.api || !waxUser) return null;

    e.preventDefault();

    try {
      const rawTx = {
        actions: [
          {
            account: 'atomicassets',
            name: 'transfer',
            authorization: [
              {
                actor: waxUser.account,
                permission: 'active',
              },
            ],
            data: {
              from: waxUser.account,
              to: 'redeemprtcol',
              asset_ids: [assetId],
              memo: 'redeem',
            },
          },
          {
            account: 'redeemprtcol',
            name: 'redeem',
            authorization: [
              {
                actor: waxUser.account,
                permission: 'active',
              },
            ],
            data: {
              asset_owner: waxUser.account,
              asset_id: assetId,
            },
          },
        ],
      };

      const option = {
        blocksBehind: 3,
        expireSeconds: 30,
        broadcast: true,
        sign: true,
      };
      await wax.api.transact(rawTx, option);

      setIsLoading(true);
      await handleRequestShippingInfo();
      setFormFields(intialFormFields);
      onSuccess();
    } catch (error) {
      console.log('redeem', error);
      onFailed(error as Error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      hasBackDrop
      backgroundColor="#ffffff"
      closeColor="#ffffff"
      width={600}
      opened={isOpen}
      onClose={onClose}
    >
      <Loading isLoading={isLoading} />
      <StyledWrapper action="">
        <StyledMainTitle>Shipping Information</StyledMainTitle>
        <StyledCustomRow>
          <StyledLabel>Name</StyledLabel>
          <StyledInput
            required
            mr="8px"
            placeholder="First Name"
            value={formFields.firstName}
            onChange={(e) => handleUpdateForm('firstName', e.target.value)}
          />
          <StyledInput
            required
            placeholder="Last Name"
            value={formFields.lastName}
            onChange={(e) => handleUpdateForm('lastName', e.target.value)}
          />
        </StyledCustomRow>
        <StyledCustomRow>
          <StyledLabel>Email</StyledLabel>
          <StyledInput
            required
            type="email"
            placeholder="Email"
            value={formFields.email}
            onChange={(e) => handleUpdateForm('email', e.target.value)}
          />
        </StyledCustomRow>
        <StyledCustomRow>
          <StyledLabel>Phone</StyledLabel>
          <StyledInput
            required
            placeholder="Mobile phone no."
            value={formFields.phone}
            onChange={(e) => handleUpdateForm('phone', e.target.value)}
          />
        </StyledCustomRow>
        <StyledCustomRow>
          <StyledLabel>Address</StyledLabel>
          <StyledInput
            required
            placeholder="Floor, Number, Road, District"
            value={formFields.address}
            onChange={(e) => handleUpdateForm('address', e.target.value)}
          />
        </StyledCustomRow>
        <StyledCustomRow>
          <StyledLabel>City</StyledLabel>
          <StyledInput
            required
            placeholder="City"
            value={formFields.city}
            onChange={(e) => handleUpdateForm('city', e.target.value)}
          />
        </StyledCustomRow>
        <StyledCustomRow>
          <StyledLabel>Post Code</StyledLabel>
          <StyledInput
            required
            placeholder="Post Code"
            value={formFields.postCode}
            onChange={(e) => handleUpdateForm('postCode', e.target.value)}
          />
        </StyledCustomRow>
        <StyledCustomRow>
          <StyledLabel>Country</StyledLabel>
          <Select
            required
            className="basic-single"
            classNamePrefix="select"
            name="color"
            placeholder=""
            isSearchable={false}
            options={countries}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                width: '100%',
                borderWidth: 2,
                borderColor: '#c5ced6',
                fontSize: 12,
                fontWeight: 500,
              }),
            }}
            components={{
              DropdownIndicator: () => (
                <img src={elevatorIcon} alt="elevator" style={{ width: 8, marginRight: 12 }} />
              ),
              IndicatorSeparator: () => null,
            }}
            onChange={(data) => handleUpdateForm('country', data!.value)}
          />
        </StyledCustomRow>
        <Row justify="center">
          <StyledRedeemButton type="submit" onClick={(e) => handleRedeem(e)}>
            <StyledButtonIcon src={exchangeIcon} background="#fff" />
            Redeem
          </StyledRedeemButton>
          <StyledCancelButton onClick={onClose}>
            <StyledButtonIcon src={closeIcon} background="#000" />
            Cancel
          </StyledCancelButton>
        </Row>
      </StyledWrapper>
    </Modal>
  );
};

export default ShippingInformation;
